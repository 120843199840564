<template>
  <div class="content" style="margin-left: 57px; margin-bottom: 70px;">


    <transition v-if="isModalWaitingApprove" name="modal">
      <div class="modal-mask"  style="overflow-y: scroll; padding-top: 100px;">
        <div class="modal-container" style="width: 660px !important;overflow: scroll;max-height: 500px;background: #f4f5f7 ">
          <div style="float: right; padding-right: 10px;">
            <img @click="isModalWaitingApprove = false" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>


          <div style="text-align: center; width: 550px; margin: 0 auto 60px;">
            <p class="content-header-text">
              Bekleyen Siparişler
            </p>

            <div style="margin-top:50px; display: flex; flex-direction: column; align-items: center; justify-content: center">
              <div v-for="waitingOrder in waitingManualApprove" class="only-payments-row" style="width: 500px; height: 100px; margin-bottom: 30px">
                <div class="first-col">
                  <div>Alıcı: <router-link target="_blank" style="color: #fa4359" :to="'/'+waitingOrder.buyer_username">{{waitingOrder.buyer_username}}</router-link></div>


                </div>
                <div class="second-col">
                  <router-link v-if="waitingOrder.order_id" style="text-decoration: none;" :to="'/orders/'+waitingOrder.order_id"  class="order-but" >
                    Siparişe Git
                  </router-link>
                  <div class="pri">
                    {{ waitingOrder.amount }}
                  </div>
                </div>

              </div>
            </div>

            <div style="margin-top: 10px; text-align: left; margin-top: 50px;">
              <p class="info-text">Not: <span class="info-description" style="font-weight: 300">Bakiyene yatması beklenen siparişlerini üst bölümde inceleyebilirsin. Bu işlem en geç 1 iş günü içinde tamamlanır.</span></p>
            </div>

          </div>
        </div>
      </div>
    </transition>

    <transition v-if="isModal" name="modal">
      <div class="modal-mask"  style="overflow-y: scroll; padding-top: 100px;">
        <div class="modal-container" style="width: 660px !important;overflow: scroll;max-height: 500px;background: #f4f5f7 ">
          <div style="float: right; padding-right: 10px;">
            <img @click="isModal = false" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>

          <loader v-if="modalLoading"/>
          <div v-else style="text-align: center; width: 550px; margin: 0 auto 60px;">
            <p class="content-header-text">
              Ödeme Detayları
            </p>

            <div style="margin-top:50px; display: flex; flex-direction: column; align-items: center; justify-content: center">
              <div v-for="modalPayout in modalPayouts" class="only-payments-row" style="width: 500px; height: 100px; margin-bottom: 30px">
                <div class="first-col">
                  <div style="">

                    <p style="text-align: left;font-weight: 400; font-size: 14px; color: #2D3640">
                      Alıcı: <router-link target="_blank" style="" :to="'/'+modalPayout.buyer">{{modalPayout.buyer}}</router-link>
                    </p>
                    <p style="margin-top: 10px; text-align: left">
                      <router-link target="_blank" style="color: #fa4359; font-size: 14px;" :to="'/orders/'+modalPayout.order_id">Siparişe Git</router-link>
                    </p>

                  </div>

                </div>
                <div class="second-col">

                  <div class="pri" style="text-align: right">
                    <p><span style="font-weight: 400; font-size: 14px">Sipariş Tutarı: <span style="font-weight: 600">{{ modalPayout.order_total }}</span></span></p>
                    <p style="margin-top: 10px; font-size: 17px;"><span style="font-weight: 400; font-size: 14px">Sana Yapılan Ödeme: </span>{{ modalPayout.amount }}</p>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </transition>

    <transition v-if="isModalTransferDetails" name="modal">

      <div class="modal-mask"  style="overflow-y: scroll; padding-top: 100px;">
        <div class="modal-container" style="width: 660px !important;overflow: scroll;max-height: 500px;background: #f4f5f7 ">
          <div style="float: right; padding-right: 10px;">
            <img @click="isModalTransferDetails = false" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>

          <loader v-if="modalLoading"/>

          <div v-else style="text-align: center; width: 550px; margin: 0 auto 60px;">
            <p v-if="modalWithdrawInfo.bounce_status !== 'BOUNCED' && modalWithdrawInfo.bounce_status !== 'UPDATED'" class="content-header-text">
              Banka Transferi Detayları
            </p>

            <div v-if="modalWithdrawInfo.bounce_status === 'BOUNCED'" style=" text-align: left; margin-top: 50px;">
              <p class="info-text">Önemli: Para çekme talebin banka hesabına aktarılamadı. <span class="info-description" style="font-weight: 300">Ödemenin sana ulaşması için sisteme girdiğin kullanıcı bilgilerin <span style="font-weight: 600; color: #2D3640;">(İsim, Soyisim, T.C. Kimlik</span> ve <span style="font-weight: 600; color: #2D3640;">IBAN numarası)</span> ile bankada kayıtlı hesap bilgilerinin birebir uyuşması gerekiyor. <br/><br/>Ödemenin tekrar tetiklenmesi için <router-link style="color: #fa4359; font-weight: 600;" to="/settings/finance">Banka ve Ödeme Bilgileri</router-link> sayfasına giderek bilgilerini tekrar güncellemen gerekiyor. Bize geri dönen ödemenin detayları ise şu şekilde: </span></p>
            </div>

            <div v-if="modalWithdrawInfo.bounce_status === 'UPDATED'" style=" text-align: left; margin-top: 50px;">
              <p class="info-text"><span class="info-description" style="font-weight: 300">Daha önce bize geri gelen ödemen 2-5 iş günü içinde altta belirtilen banka hesap bilgilerine tekrar aktarılacak 💸 Eğer alttaki bilgilerde bir hata varsa ödeme aktarımı başlayana kadar <router-link style="color: #fa4359; font-weight: 600;" to="/settings/finance">Banka ve Ödeme Bilgileri</router-link> sayfasına giderek bilgilerini günceleyebilirsin. </span></p>
            </div>

            <div style="margin-top:50px; text-align: left ">

              <div class="bank-info" >
                <div class="contact-item">
                  <label class="contact-label left">Hesap Sahibi</label>
                  <p class="contact-text right" style="text-transform: capitalize;font-weight: 800;">{{modalWithdrawInfo.name}}</p>
                </div>
                <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
                <div class="contact-item">
                  <label class="contact-label left">IBAN</label>
                  <p class="contact-text right" style="font-weight: 800;">{{modalWithdrawInfo.iban}}</p>
                </div>
                <template v-if="modalWithdrawInfo.bounce_status !== 'BOUNCED' && modalWithdrawInfo.bounce_status !== 'PAYOUT_COMPLETED' && modalWithdrawInfo.bounce_status !== 'UPDATED'">
                  <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
                  <div class="contact-item">
                    <label class="contact-label left">Transfer Tarihi</label>
                    <p class="contact-text right"> {{ Number(modalWithdrawInfo.created_at) | customDateFormat }}</p>
                  </div>
                </template>
                <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" ></div>
                <div class="contact-item">
                  <label class="contact-label left">Tutar</label>
                  <p class="contact-text right" style="font-weight: 800;">{{ modalWithdrawInfo.amount }}</p>
                </div>
                <template v-if="modalWithdrawInfo.bounce_status !== 'BOUNCED'  && modalWithdrawInfo.bounce_status !== 'UPDATED'">
                  <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" ></div>
                  <div class="contact-item">
                    <label class="contact-label left">EFT Açıklaması</label>
                    <p class="contact-text right">{{ modalWithdrawInfo.description }}</p>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="modalWithdrawInfo.bounce_status !== 'BOUNCED'" style="margin-top: 10px; text-align: left; margin-top: 50px;">
              <p class="info-text">Önemli: <span class="info-description" style="font-weight: 300">Paranın sana ulaşması için sisteme girdiğin kullanıcı bilgilerin <span style="font-weight: 600; color: #2D3640;">(İsim, Soyisim, T.C. Kimlik</span> ve <span style="font-weight: 600; color: #2D3640;">IBAN numarası)</span> ile bankada kayıtlı hesap bilgilerinin birebir uyuşması gerekiyor. Sadece kendi adına veya şirketin adına açılmış bir hesaba transfer yapılabilir. </span></p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition v-if="isModalWalletToCard" name="modal">
      <div class="modal-mask"  style="overflow-y: scroll; padding-top: 100px;">
        <div class="modal-container" style="width: 660px !important;overflow: scroll;max-height: 500px;background: #f4f5f7 ">
          <div style="float: right; padding-right: 10px;">
            <img @click="isModalWalletToCard = false" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>

          <loader v-if="modalLoading"/>
          <div v-else style="text-align: center; width: 550px; margin: 0 auto 60px;">
            <p class="content-header-text">
              Karta İade Bilgileri
            </p>

            <div style=" text-align: left; margin-top: 50px;">
              <p class="info-text"> <span class="info-description" style="font-weight: 400">İade işlemini kart ekstrende <strong> 3-10 iş günü</strong> içinde görmeni bekliyoruz. Aksi bir durumda altta belirtilen Host Referans numarası ve Auth Code numarasını bankana ileterek son durum hakkında bilgi alabilirsin.</span></p>
            </div>

            <div v-for="walletToCard in modalWalletToCardTransactions" style="margin-top:50px; text-align: left ">
              <div class="bank-info" >
                <div class="contact-item">
                  <label class="contact-label left">İade Tarihi</label>
                  <p class="contact-text right"> {{ Number(walletToCard.created_at) | customDateFormat }}</p>
                </div>
                <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
                <div class="contact-item">
                  <label class="contact-label left">İade Host Referans Numarası</label>
                  <p class="contact-text right" style="text-transform: capitalize;font-weight: 800;">{{walletToCard.hostReference}}</p>
                </div>
                <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
                <div class="contact-item">
                  <label class="contact-label left">İade Auth Code</label>
                  <p class="contact-text right" style="font-weight: 800;">{{walletToCard.authCode}}</p>
                </div>
                <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" ></div>
                <div class="contact-item">
                  <label class="contact-label left">Tutar</label>
                  <p class="contact-text right" style="font-weight: 800;">{{ walletToCard.amount }}</p>
                </div>
              </div>
              <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" ></div>
              <hr/>
            </div>

          </div>
        </div>
      </div>
    </transition>

    <div class="content-header">
      <div>
        <p class="content-header-text" >
          {{1===1 || user.seller.isSeller ? 'Bakiyem' : 'Ödemeler'}}
        </p>
        <p style="font-size: 17px; color: #768191; font-weight: normal; margin-top: 10px; line-height: 1.55" :style="(1===1 || user.seller.isSeller) && ' width: 500px; '">
          Bionluk bakiye haraketlerine ve yaptığın tüm ödemelere bu alandan ulaşabilirsin. 26 Mayıs 2021 12:00 tarihinden önceki hareketlerine ise <router-link to="/panel/finans">bu linkten</router-link> göz atabilirsin.
        </p>
      </div>
      <div v-if="1===1 || user.seller.isSeller" class="content-balance">
        <div style="text-align: center">
          <p class="balance">{{walletAmount | number_format(2)}}<span class="tl">TL</span></p>
          <p style="margin-top:3px;font-size: 18px;font-weight: 500;line-height: 1.11;">Toplam Bakiyen</p>
         <div v-if="withdrawalAmount" style="display: flex; justify-content: space-between">
           <CustomButton @click="openWithdrawModal()" style="margin-top:30px; width: 150px;" :button-style="'height:44px;'" :button-type="'borderNoHover'">Para Çek</CustomButton>
         </div>

        </div>
      </div>
    </div>

    <div v-if="1===1 || user.seller.isSeller" class="tabs">
      <div  @click="changeTab('wallet')" :class="tab === 'wallet' ? 'tab-bar-active' : 'tab-bar'" style="border-top-left-radius: 5px;">
        <p class="text">Bakiye İşlemleri</p>
      </div>
      <div @click="changeTab('payments')" :class="tab === 'payments' ? 'tab-bar-active' : 'tab-bar'" style="border-top-right-radius: 5px;">
        <p class="text">Yaptığım Ödemeler</p>
      </div>
    </div>


    <loader v-if="!pageLoaded"/>

    <div v-if="waitingSettlement"  class="finance-info for-seller">
      <div style="margin-left: 20px">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
      </div>
      <div style="margin-left: 15px">
        <div class="description">
          <p class="disapproval-row" style="font-size: 17px; font-weight: 400">Onaylanan ama bakiyene henüz yatmayan satışların var. Bu sipariş tutarlarının bakiyene yansıma süresi ortalama 5 dakikadır. ⏳ Daha sonra tekrar bu sayfayı ziyaret edebilirsin.</p>
        </div>
      </div>
    </div>

    <div v-if="!!waitingManualApprove.length"  class="finance-info for-buyer">
      <div style="margin-left: 20px">
        <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-buyer-icon"/>
      </div>
      <div style="margin-left: 15px">
        <div class="title">Bakiyene henüz yatmayan satışların var</div>
        <div class="description">
          <p class="disapproval-row" >Onaylanan ama bakiyene yansıması için ön provizyon bekleyen {{waitingManualApprove.length}} siparişin var. Bu işlem en geç 1 iş günü içinde tamamlanır Bu siparişler hakkında detaylı bilgiyi <span @click="isModalWaitingApprove = true" class="hover-colred">buraya tıklayarak</span> alabilirsin.</p>
        </div>
      </div>
    </div>


    <div v-if="pageLoaded && tab === 'payments'" style="display: flex; width: 843px; justify-content: flex-end; margin-top: 20px;">
      <select v-model="paymentsType" @change="paymentsTypeChanged" name="status" class="cuper-drop-slim" >
        <option :value="'CARD_PAYMENT'">Sadece Kartla Yapılanlar</option>
        <option :value="'CARD_AND_WALLET_PAYMENT'">Kart ve Bakiye ile Yapılanlar</option>
      </select>
    </div>

    <div v-if="pageLoaded && tab === 'wallet'" style="display: flex; width: 843px; justify-content: flex-end; margin-top: 20px;">
      <select v-model="walletType" @change="walletTypeChanged" name="status" class="cuper-drop-slim" >
        <option :value="'all'">Tüm İşlemler</option>
        <option :value="'WITHDRAW'">Para Çekme Talepleri</option>
        <option :value="'SETTLEMENT_EARNINGS'">Sipariş Ödemeleri</option>
        <option :value="'PAYMENT_REDEEM'">Bakiyemden Yaptığım Harcamalar</option>
      </select>
    </div>

    <div v-if="pageLoaded && !payments.length" style="height: 400px;  width: 844px;  display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <template v-if="tab === 'wallet'">
        <img v-if="walletType === 'all'" style="width: 223px; height: 123px;" src="https://gcdn.bionluk.com/uploads/message/4a452e7c-41be-483d-a7c3-a736d0d27aa6.png"/>
        <div v-if="walletType === 'all'" style="width:560px; margin-top: 24px; line-height: 1.44; text-align: center; color:#5e6b79; font-size: 20px; font-weight: 300">
          Henüz bir bakiye işlemin yok. Bionluk bakiye işlemlerini bu sayfa üzerinden takip edebilirsin.
        </div>
        <div v-else style="width:560px; margin-top: 24px; line-height: 1.44; text-align: center; color:#5e6b79; font-size: 20px; font-weight: 300">
          Aradığın kriterlerde sonuç bulunamadı. Arama filtrelerini değiştirip tekrar deneyebilirsin.
        </div>
      </template>
      <div  v-if="tab === 'payments'" style="width:560px; margin-top: -94px; line-height: 1.44; text-align: center; color:#5e6b79; font-size: 20px; font-weight: 300">
        {{paymentsType === 'CARD_PAYMENT' ? 'Sadece kartla yapılan' : 'Hem bakiye ile hem de kartla'}} yapılan ödeme bulunamadı. Arama filtrelerini değiştirip tekrar deneyebilirsin.
      </div>
    </div>





    <div v-for="(payment, index) in payments" :key="index" class="payment-wrapper" style="margin-top: 30px">
      <div v-if="tab === 'payments'" class="only-payments-row">
        <div class="first-col">
          <div class="date">
            {{ Number(payment.created_at) | customDateFormat('dd MMM yyyy') }}
          </div>
          <div class="descrip">
            {{ payment.description }}
          </div>
        </div>
        <div class="second-col">
          <router-link v-if="payment.order_id" style="text-decoration: none;" :to="'/orders/'+payment.order_id"  class="order-but" >
            Siparişe Git
          </router-link>
          <div class="pri">
            {{payment.total}}
          </div>
          <div style="margin-left: 10px;">
            <div v-if="payment.wallet_price"
              :data-balloon="'Bu ödemenin ' + payment.wallet_price + ' lik bölümü bakiyenden geri kalan bölümü kartından yapıldı. ' "
              data-balloon-pos="up"
              data-balloon-length="medium"
            >
              <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-i"/>
            </div>

          </div>
        </div>

      </div>
      <div v-else class="payment">

        <div style="display: flex; justify-content: flex-end">
          <div style="font-size: 14px; color: #5e6b79; font-weight: normal;">{{ Number(payment.created_at) | customDateFormat('dd MMM yyyy') }}</div>
        </div>
        <div class="description-box" style="margin: 20px auto 0">
          <div class="description">
            {{ payment.description }}
          </div>
        </div>
        <div class="payment-footer" style="justify-content: space-between">
          <div style="display: flex;">
            <div style="margin-left: 10px; font-weight: bold; font-size: 20px;" :style="`color: ${payment.total_color}`">
              {{payment.total}}
            </div>
          </div>
          <router-link v-if="payment.order_id" style="text-decoration: none;" :to="'/orders/'+payment.order_id"  class="analyse-label" >
           Siparişe Git
          </router-link>
          <div v-else-if="payment.button_action_text" style="display: flex; align-items: center">
            <custom-button @click="openTheModal(payment)"  :button-type="payment.button_action_type" style="height: 36px; padding-bottom: 2px; "  button-style=" border-radius: 3px; width:140px; font-weight:400, height:32px; font-size:13px; ">{{payment.button_action_text}}</custom-button>
          </div>
          <div v-else-if="payment.status_text" style="display: flex; align-items: center">
            <p v-if="payment.cancel_button" @click="cancelWithdraw(payment.tx_id)" class="cancel-button"> İptal Et</p>
            <div class="analyse-label" :style="`border: solid 1px ${payment.status_color}; color: ${payment.status_color}`">
              {{ payment.status_text }}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div v-if="seemorediv && !showLoader && pageLoaded" @click="retrieveHistory(true)"
         style="cursor: pointer; margin:40px 0;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
      + Daha Fazla
    </div>
    <loader v-if="showLoader"/>
  </div>
</template>

<script>
import CustomButton from "@/pages/components/CustomButton";
import EventBus from "@/eventBus";
  export default {
    components:{ CustomButton},
    name: "src-pages-body-workstation-finance-v2",
    data() {
      return {
        pageLoaded: null,
        payments: [],
        seemorediv: false,
        page: 0,
        showLoader: false,

        tab: 'wallet',
        walletAmount: 0,
        withdrawalAmount:0,
        ibanName:  '',
        ibanNo: '',
        waitingSettlement:false,
        waitingManualApprove:[],
        paymentsType: 'CARD_PAYMENT',
        walletType:'all',
        isModal:false,
        modalPayouts:[],
        isModalTransferDetails:false,
        modalWithdrawInfo:{},
        isModalWaitingApprove:false,
        isAkbankIban: false,
        isAkbankUser: false,
        sellerType: '',

        modalLoading:false,

        isModalWalletToCard:false,
        modalWalletToCardTransactions:[],

      }
    },

    methods: {

      openTheModal(payment){
        if(payment.transaction_type === 'WITHDRAW'){
          this.isModalTransferDetails = true;
          this.modalLoading = true;
        }

        if(payment.transaction_type === 'REFUND_WALLET_TX_TO_CARD'){
          this.isModalWalletToCard = true;
          this.modalLoading = true;
        }

        if(payment.transaction_type === 'SETTLEMENT_EARNINGS'){
          this.isModal = true;
          this.modalLoading = true;
        }


        if(this.modalLoading){
          this.api.user.retrieveCGWalletDetail(payment.tx_id, payment.transaction_type, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                if(payment.transaction_type === 'WITHDRAW'){
                  this.modalWithdrawInfo = result.data;
                }
                if(payment.transaction_type === 'REFUND_WALLET_TX_TO_CARD'){
                  this.modalWalletToCardTransactions = result.data;
                }
                if(payment.transaction_type === 'SETTLEMENT_EARNINGS'){
                  this.modalPayouts = result.data;
                }

                this.modalLoading = false;
              } else {
                this.$toasted.global.errorToast({description: result.message});
                this.modalLoading = false;
                this.isModalTransferDetails = false;
              }
            })
            .catch(() => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              this.modalLoading = false;
              this.isModalTransferDetails = false;
            });
        }

      },

      paymentsTypeChanged(){
        if(!this.pageLoaded){return false;}
        this.pageLoaded = false;
        this.page = 0;
        this.retrievePayments()
      },
      walletTypeChanged(){
        if(!this.pageLoaded){return false;}
        this.pageLoaded = false;
        this.page = 0;
        this.retrieveWallet()
      },
      cancelWithdraw(tx_id){


        if(!this.pageLoaded){
          return false;
        }

        if (confirm('Para çekme işlemini iptal etmek üzeresin. Tutar bakiyene tekrar aktarılacak. Onaylıyor musun?') === true) {
          this.pageLoaded = false;
          this.api.user.cancelWithdraw(tx_id, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
                this.page = 0;
                this.retrieveHistory();
              } else {
                this.$toasted.global.errorToast({description: result.message});
                this.pageLoaded = true;
              }
            })
            .catch(() => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              this.pageLoaded = true;
            });
        }

      },
      openWithdrawModal(){
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WITHDRAW_FUNDS, info: {data: {ibanNo:this.ibanNo, ibanName:this.ibanName, withdrawalAmount:this.withdrawalAmount, walletAmount:this.walletAmount, isAkbankIban:this.isAkbankIban, isAkbankUser:this.isAkbankUser, sellerType:this.sellerType}}});
      },
      changeTab(tab){
        if(this.tab !== tab){
          this.tab = tab;
          this.page = 0;
          this.retrieveHistory();
        }

      },

      retrieveWallet(seemore){
        if(!seemore){
          this.payments = [];
        }
        this.api.user.retrieveCGWallet(this.page, this.walletType, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              if(seemore){
                this.payments = this.payments.concat(result.data.transactions)
              } else {
                this.payments = result.data.transactions;
              }

              this.ibanName = result.data.iban_name;
              this.ibanNo = result.data.iban_no;
              this.isAkbankIban = result.data.is_akbank_iban;
              this.isAkbankUser = result.data.is_akbank_user;
              this.sellerType = result.data.seller_type;
              this.walletAmount = result.data.amount;
              this.withdrawalAmount = result.data.withdrawalAmount;
              this.seemorediv = result.data.seemorediv;
              this.waitingSettlement = result.data.waiting_settlement;
              this.waitingManualApprove = result.data.waiting_manual_approve;
              this.page++;
            } else {
              this.$router.push('/')
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.showLoader = false
            this.pageLoaded = true
          })
      },

      retrievePayments(seemore){
        if(!seemore){
          this.payments = [];
        }
        this.api.user.retrieveCGPaymentHistory(this.page, this.paymentsType, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              if(seemore){
                this.payments = this.payments.concat(result.data.transactions)
              } else {
                this.payments = result.data.transactions;
              }

              this.seemorediv = result.data.seemorediv
              this.page++;
            } else {
              this.$router.push('/')
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.showLoader = false
            this.pageLoaded = true
          })
      },

      retrieveHistory(seemore) {
        if (seemore) {
          this.showLoader = true
        } else {
          this.pageLoaded = false
        }
        if(this.tab === 'payments'){
          this.retrievePayments(seemore);
        } else {
          this.retrieveWallet(seemore);
        }
      }
    },

    created() {
      if(1===2 && !this.user.seller.isSeller){
        this.tab = 'payments';
      }
      this.EventBus.$on("refreshWallet", () => {
        this.page = 0;
        this.retrieveHistory();
      });
      this.retrieveHistory();
    },
    beforeDestroy() {
      this.EventBus.$off('refreshWallet');
    }
  }
</script>

<style scoped lang="scss">


.hover-colred{
  color: #fa4359;  font-weight: 600; cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.info-text {
  font-size: 16px;
  color: #2d3640;
  font-weight:600;
  margin-top: 12px;
  line-height: 1.44;
}


.bank-info {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.separator {
  border-bottom: 1px solid #eaedf2;
  width: 100%;
}
.contact-item {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;

  .left {
    width: 25%;
    align-self: center;
  }

  .right {
    width: 75%;
  }
}

.contact-label {
  font-size: 16px;
  color: #8b95a1;
  font-weight: 400;
  margin: 0;
}

.contact-text {
  font-size: 16px;
  color: #2d3640;
  font-weight: 400;
}


.info-i /deep/{
  width: 16px;
  height: 16px;
  margin-top: 6px;
  path{
    fill: #5e6b79;
  }
}

.only-payments-row{
  height: 100px;
  border-radius: 10px;
  box-shadow: 0 0 0 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: space-between;
  .first-col{
    display: flex;
    .date{
      font-size: 18px;
      line-height: 1.11;
      color: #5e6b79;
      width: 150px;
    }
    .descrip{
      line-height: 1.11;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
    }
  }
  .second-col{
    display: flex;
    align-items: center;
    justify-self: flex-end;
    justify-content: flex-end;

    width: 270px;
    .order-but{
      font-size: 13px;
      width: 120px;
      height: 36px;
      border-radius: 18px;
      border: solid 1px #bfc8d2;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8b95a1;
      cursor: pointer;
      margin-right: 50px;
      &:hover{
        opacity: 0.8;
      }
    }

    .pri{
      font-size: 20px;
      font-weight: 600;
      color: #2d3640;
    }
  }

}

.cancel-button{
  cursor:pointer;margin-right: 25px; color: #fa4359; font-weight: 500; font-size: 14px;
  &:hover{
    text-decoration: underline;
  }
}
.tabs{

  margin-top: 30px;
  cursor: pointer;
  display: flex; height: 70px; width: 843px;

  .tab-bar{
    width: 422px;
    height: 67px;
    border-bottom: 3px solid #e6e9ee;
    background-color: #e6e9ee;
    display: flex;
    align-items: center;
    justify-content: center;
    .text{
      font-size: 18px;
      font-weight: 500;
      color: #8b95a1;

    }

    &:hover{
      .text{
        font-size: 18px;
        font-weight: 600;
        color: #3c4654;

      }
    }
  }


  .tab-bar-active{
    width: 422px;
    height: 67px;
    background-color: #ffffff;
    border-bottom: 3px solid #2d3640;
    display: flex;
    align-items: center;
    justify-content: center;

    .text{
      font-size: 18px;
      font-weight: 600;
      color: #3c4654;

    }
  }

}

  .finance-info{
    width: 843px;
    height: 100px;
    border-radius: 10px;
    border: solid 1px rgba(242, 153, 51, 0.3);
    background-color: rgba(242, 153, 51, 0.2);
    margin-top: 30px;
    display: flex;
    align-items: center;
    position: relative;
    &.for-seller {
      border: solid 1px rgba(90, 38, 164, 0.3);
      background-color: rgba(90, 38, 164, 0.2);
    }
    .for-seller-icon /deep/{
      width: 36px;
      height: 36px;
      path{
        fill: #5a26a4;
      }
    }
    .for-buyer-icon /deep/{
      width: 36px;
      height: 36px;
      path{
        fill: #f29933;
      }
    }
    .delete-icon /deep/{
      width: 16px;
      height: 16px;
      path{
        fill: #2d3640;
      }
    }
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #343d4a;
      padding-bottom: 4px;
    }
    .description{
      width: 668px;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.29;
      color: #4f596c;
    }
  }
  .payment-wrapper{
    width: 844px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    .payment{
      width: 783px;
      margin: 20px auto;
      .description-box{
        width: 783px;
        border-radius: 10px;
        background-color: #eff3f8;
        text-align: center;
        .description {
          width: 520px;
          margin: 0 auto;
          padding: 12px 0;
          font-size: 14px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #898ea9;
        }
      }
      .payment-footer{
        margin-top: 22px;
        display: flex;
        .live-analyse-button{
          text-align: center;
          cursor: pointer;
          width: 185px;
          height: 30px;
          line-height: 29px;
          border-radius: 4px;
          background-color: #5a26a4;
          color: #ffffff;
          font-size: 13px;
          display: flex;
          .analytics-icon /deep/{
            width: 16px;
            height: 16px;
            path {
              fill: #ffffff!important;
            }
          }
          &.active{
            background-color: #fd4056;
          }
        }
        .analyse-label{
          text-align: center;
          width: 125px;
          height: 28px;
          line-height: 27px;
          border-radius: 4px;
          border: solid 1px #5a26a4;
          font-size: 13px;
          background-color: #ffffff;
          color: #5a26a4;
          &.completed{
            border: solid 1px #6dce6a;
            color: #6dce6a;
          }
        }
      }
      .separator{
        margin-top: 20px;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
      .analytics{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .item{
          &:nth-child(n+2) {
            margin-left: 68px;
          }
          .title {
            display: flex;
            height: 22px;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.38;
            color: #8b95a1;
            &.active{
              color: #2d3640;
            }
            .info-icon /deep/{
              width: 14px;
              height: 14px;
              path {
                fill: #8b95a1!important;
              }
            }
            .info-icon-active /deep/{
              width: 14px;
              height: 14px;
              path {
                fill: #2d3640!important;
              }
            }
            .data-balloon{
              display: inline-flex;
              cursor: help;
              margin: auto;
            }
          }
          .value{
            height: 42px;
            font-size: 34px;
            font-weight: bold;
            letter-spacing: -0.35px;
            color: #5e6b79;
            text-align: center;
            &.active{
              color: #2d3640;
            }
            &.active-green{
              color: #00a575 !important;
            }
          }
        }
      }
    }
  }
  .content-header-text{
    font-size: 34px;
    font-weight: normal;
    color: #3c4654;
  }
  .content-header{
    margin-top: 31px;
    margin-bottom: 13px;
    width: 849px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .content-balance{
    width: 300px; height: 200px;  color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid rgba(101, 196, 219, 0.3); background-color: rgba(101, 196, 219, 1);

    .balance{
      font-size: 36px;
      font-weight: bold;
      .tl{
        font-size: 28px;
        font-weight: 600;
        padding-left: 3px;
      }


    }
  }
</style>
